import React from "react";

import "./App.css";

import logo from "./assets/logo.svg";

function App() {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);

  const fromApp = params.get("app");

  return (
    <div class="container">
      {!fromApp && (
        <>
          <img src={logo} alt="logo" />
          <h1>Moveciti - Política de privacidade</h1>
        </>
      )}

      <h4>1. FUNCIONAMENTO DO APLICATIVO</h4>
      <p>
        1.1 - Esta Política de Privacidade tem como por objetivo esclarecer a forma que a
        Moveciti coleta, utiliza, armazena, compartilha e descarta os dados dos Usuários
        do Aplicativo, em relação às informações capazes de identificá-los, tais como as
        informações fornecidas voluntariamente e informações pessoais.
      </p>
      <p>
        1.2 - O cadastro e o acesso ao Aplicativo implicam na aceitação total das
        disposições desta Política. A Moceviti recomenda que os Usuários consultem a
        presente Política, antes de fornecer quaisquer informações pessoais. Caso não haja
        concordância com alguma disposição aqui presente, não use o Aplicativo.
      </p>
      <p>
        1.3 - Ao declarar que leu e concorda com a Política e com os Termos de Uso o
        Usuário autoriza, de forma livre e expressa, que seus dados e informações sejam
        coletados, armazenados, utilizados, tratados, compartilhados e descartados na
        forma estabelecida nesta Política.
      </p>

      <h4>2. COLETA DAS INFORMAÇÕES PESSOAIS</h4>
      <p>
        2.1 - As informações coletadas pelo Moveciti têm o objetivo de permitir o regular
        cadastro dos seus Usuários, de modo a viabilizar a prestação dos serviços
        ofertados de forma completa.
      </p>
      <p>
        2.2 - É possível que o usuário acesse o aplicativo sem o fornecimento dos seus
        dados pessoais, no entanto, algumas funcionalidades não estarão disponíveis.
      </p>
      <p>
        2.3 - Ao efetuar o login com o Apple ou o Google, o Usuário disponibilizará os
        seguintes dados considerados imprescindíveis para a prestação dos serviços de
        forma completa (“Dados Essenciais”): nome, endereço de e-mail, idioma preferido e
        foto do perfil.
      </p>
      <p>
        2.4 - Os Dados Essenciais são aqueles necessários à prestação dos serviços
        ofertados de forma completa ao Usuário no Aplicativo, sendo certo que para a
        utilização de outras funcionalidades, como comprar bilhetes, outros dados poderão
        ser solicitados para fins de utilização específica.
      </p>
      <p>
        2.5 - Os Dados Adicionais serão tratados, em conjunto com os demais Dados
        fornecidos pelos Usuários na mesma forma e nos mesmos moldes previstos em
        legislação específica, especialmente de acordo com a LGPD, como previsto nesta
        Política.
      </p>
      <p>
        2.6 - Também poderão ser coletadas informações adicionais, a saber, dados que não
        permitam a correlação com qualquer usuário especificamente.
      </p>

      <h4>3. FINALIDADE DAS INFORMAÇÕES COLETADAS</h4>
      <p>
        3.1 - O usuário poderá, por meio do Aplicativo, enviar comentários e informações.
        O usuário reconhece, desde já, que a Moveciti não tem qualquer conhecimento ou
        ingerência sobre o conteúdo das informações enviadas pelo Usuário ao Aplicativo,
        não se responsabilizando, em nenhuma hipótese, pelo conteúdo das mesmas e por suas
        consequências. O Usuário será o único responsável pelo conteúdo de seus
        comentários e manifestações, devendo respeitar a moral, a ética, os bons costumes
        e os direitos de terceiros, bem como a legislação nacional em vigor e as demais
        disposições dos Termos de Uso.
      </p>
      <p>
        3.2 - O conteúdo enviado pelo Usuário no ambiente do Aplicativo é não
        confidencial. Todo conteúdo enviado no ambiente do Aplicativo poderá permanecer
        disponível para empresas operadoras do transporte público nas localidades,
        empresas operadoras de sistemas de venda de créditos de bilhetes eletrônicos de
        transporte, órgãos gestores ou fiscalizadores do transporte público , e órgãos de
        segurança pública nas localidades. O Usuário fica ciente de que responderá, em
        qualquer esfera, por todo e qualquer dano causado pela infração às regras aqui
        dispostas, eximindo a Moveciti de qualquer responsabilidade relativa a tais fatos,
        aspectos, direitos e/ou situações.
      </p>
      <p>
        3.3 - Ao declarar que leu e concorda com a Política e com os Termos de Uso o
        Usuário autoriza, de forma livre e expressa, que seus dados e informações sejam
        coletados, armazenados, utilizados, tratados, compartilhados e descartados na
        forma estabelecida nesta Política.
      </p>
      <p>
        3.4 - Localização. Para a plena utilização do Aplicativo, é imprescindível que as
        informações de acesso relativas à localização do usuário sejam utilizadas.
      </p>
      <p>
        3.5 - Métricas de acesso. A Moveciti utiliza ferramentas, próprias ou de
        terceiros, para obter estatísticas e métricas a respeito do perfil dos usuários do
        Aplicativo e da forma como o Aplicativo vem sendo utilizado, aprimorando serviços
        e conteúdo em razão desses resultados e identificando erros de programação que
        possam afetar a utilização do Aplicativo;
      </p>
      <p>
        3.6 - Publicidade. As Informações Pessoais poderão ser utilizadas para a
        personalização de ofertas eventualmente exibidas no ambiente interno do Aplicativo
        ou através de notificação push, com o objetivo de oferecer serviços e produtos
        relevantes para o usuário. Esse procedimento é realizado de forma automatizada,
        garantindo a confidencialidade dos dados.
      </p>

      <h4>4. ARMAZENAMENTO DAS INFORMAÇÕES E SEGURANÇA</h4>
      <p>
        4.1 - A Moveciti armazena as informações do Usuário somente durante o período
        necessário para as finalidades apresentadas nesta Política de Privacidade,
        respeitando o período de retenção de dados determinado contratualmente e pela
        legislação aplicável.
      </p>
      <p>
        4.2 - Os Dados e as informações coletadas são armazenados em serviços de nuvem
        confiáveis de parceiros que podem estar localizados no Brasil ou em outros países,
        tais como: Estados Unidos da América, e em países da Europa, que sejam usualmente
        utilizados por empresas de tecnologia.
      </p>
      <p>
        4.3 - Suas informações, incluindo seus dados pessoais, são utilizadas para
        fornecer e melhorar, estética e funcionalmente, as Aplicações e Serviços Moveciti,
        além de outras finalidades estabelecidas nesta Seção. Exceto conforme previsto
        abaixo, o Moveciti não compartilhará suas informações com terceiros.
      </p>
      <p>
        4.4 - Especificamente, Moveciti deverá utilizar suas informações para os seguintes
        propósitos:
      </p>
      <ul>
        <li>
          Fornecer, entregar, manter, desenvolver, aprimorar, divulgar e ampliar o uso do
          Moveciti Aplicativos e Serviços;
        </li>
        <li>
          Identificar você e personalizar sua experiência e uso das Aplicações e Serviços
          Moveciti, inclusive para fins promocionais;
        </li>
        <li>
          Gerar análises estatísticas e relatórios sobre o funcionamento e operação da
          Moveciti e Serviços, para o benefício do Moveciti e/ou de seus parceiros;
        </li>
        <li>
          Permitir que o Moveciti entre em contato com você a respeito das Aplicações e
          Serviços que ele disponibiliza e fornecer suporte ao usuário;
        </li>
        <li>
          Fornecer e melhorar os mecanismos de segurança e monitoramento para as
          Aplicações e Serviços Moveciti, incluindo o monitoramento de segurança durante
          seu uso;
        </li>
        <li>
          Monitorar a prestação de serviços pelos parceiros e garantir, na medida do
          possível, sua qualidade e segurança;
        </li>
        <li>
          Detectar e proteger o Moveciti e os usuários contra fraudes, abusos ou atos
          ilegais, bem como preservar a segurança e a integridade física do usuário;
        </li>
        <li>
          Detectar e impedir o uso de aplicativos e programas para fins fraudulentos ou
          para alterar a funcionalidade dos aplicativos e serviços fornecidos pelo
          Moveciti;
        </li>
        <li>Desenvolver novos produtos, serviços e funcionalidades;</li>
        <li>Realizar controle de risco e análise de antecedentes criminais; </li>
        <li>
          Cumprir as obrigações legais de coleta e compartilhamento de dados com o
          governo, na forma de leis e regulamentos aplicáveis;
        </li>
        <li>
          Possibilitar a defesa do Moveciti em quaisquer eventuais processos judiciais.
        </li>
        <li>
          Cumprir com as normas anti-lavagem de dinheiro e financiamento do terrorismo
          (KYC), verificações de perfil e histórico de transações, de acordo com os
          regulamentos aplicáveis sobre prevenção de lavagem de dinheiro.
        </li>
        <li>
          Suas informações de contato são utilizadas para que O Moveciti se comunique
          diretamente com você e lhe envie informações relevantes, incluindo informações
          sobre transações, avisos sobre a aplicação e serviços, ofertas especiais,
          anúncios de eventos, notícias, melhores práticas e muito mais.
        </li>
      </ul>
      <p>
        O Moveciti pode utilizar outras informações sobre você disponíveis em fontes
        terceiras e combiná-las com informações coletadas diretamente de você, de acordo
        com esta Política de Privacidade, para todos os fins estabelecidos nesta Seção.
        Nesse caso, somente as informações coletadas diretamente de você pelo Moveciti e o
        resultado da combinação são cobertas e sujeitas a esta Política de Privacidade.
      </p>
      <p>
        O Moveciti não deverá compartilhar ou fornecer a terceiros suas informações,
        incluindo seus dados pessoais, exceto:
      </p>
      <ul>
        <li>quando você solicitar ou autorizar o Moveciti a compartilhar;</li>
        <li>
          para permitir a entrega e operação do Moveciti Aplicações e Serviços da maneira
          e forma adequadas que você contratou;
        </li>
        <li>
          aos parceiros comerciais que prestam serviços através da plataforma, ou que
          trabalham em nome do Moveciti, incluindo métodos de pagamento, de acordo com as
          especificações e diretrizes determinadas pelo Moveciti, incluindo a provisão
          contratual do dever de manter a confidencialidade das informações por esses
          terceiros;
        </li>
        <li>
          aos usuários que utilizam a plataforma para contratar o parceiro, sendo que as
          informações básicas do respectivo parceiro contratado serão fornecidas;
        </li>
        <li>
          aos órgãos, autoridades e outras entidades do poder público, para cumprir com as
          leis e regulamentos aplicáveis às Aplicações, Serviços e/ou o serviço prestado
          pelos usuários das plataformas Moveciti;
        </li>
        <li>
          a agências, autoridades e outras entidades governamentais, assim como a pessoas
          físicas ou jurídicas, a fim de cumprir com obrigações legais ou ordens
          judiciais;
        </li>
        <li>
          à autoridade policial ou ao representante do Ministério Público,
          independentemente da existência de obrigação legal específica ou ordem judicial,
          quando, a critério dO Moveciti, houver suspeita, violação flagrante ou
          investigação de crime com violência ou ameaça grave envolvendo usuários das
          Aplicações, ou necessário para proteger os direitos, propriedade ou segurança do
          Moveciti, seus usuários ou terceiros;
        </li>
        <li>
          para o exercício e defesa de qualquer dos direitos do Moveciti, a seu critério,
          inclusive em processos judiciais ou administrativos; e
        </li>
      </ul>

      <h4>5. ACESSO A LOCALIZAÇÃO</h4>

      <p>
        5.1 - O Moveciti utiliza os dados de localização de seu dispositivo a fim de
        fornecer o melhor serviço para você. Estas informações são usadas para garantir a
        máxima segurança e conforto em sua viagem, entregas e outros serviços.
      </p>
      <p>
        5.2 - <b>Permitir que o aplicativo acesse a localização o tempo todo.</b> Neste
        modo, podemos coletar dados de localização o tempo todo, sem ter que abrir o
        aplicativo. Os dados serão coletados em segundo plano, sem interferência notável
        com o plano de dados do dispositivo ou da bateria. É o modo mais apropriado para
        que possamos fornecer o serviço de forma mais dinâmica e poder melhorar nossas
        aplicações;
      </p>
      <p>
        5.3- <b>Permitir que o aplicativo acesse o local somente durante o uso. </b>
        Os dados só serão coletados quando a aplicação estiver aberta.
      </p>
      <p>
        5.4 - <b>Negar acesso ao local.</b> O Moveciti não poderá acessar seus dados de
        localização e, consequentemente, não poderemos fornecer muitos recursos que se
        baseiam nestas informações.
      </p>
      <p>
        5.5 - Caso você não forneça alguma das informações solicitadas, o Moveciti não
        pode garantir a qualidade e precisão das Aplicações e Serviços do Moveciti. Caso
        você não forneça informações essenciais para a operação das Aplicações ou da
        prestação dos Serviços.
      </p>

      <h4>6. DA EXCLUSÃO DA CONTA</h4>
      <p>
        6.1 - Caso o usuário não tenha mais interesse nos serviços prestados pela
        plataforma, o mesmo poderá solicitar ou realizar a exclusão do cadastro. Uma vez
        concluído o precedimento, todos os dados serão excluidos de forma imediata, note
        que a exclusão não pode ser desfeita. A exclusão da conta do usuário poderá ser
        realizada pelas seguintes formas:
      </p>
      <ul>
        <li>
          Mediante pedido aos administradores da Plataforma através do e-mail de suporte
          ao usuário suporte.moviciti@gmail.com.
        </li>
        <li>
          Através do aplicativo: No menu lateral selecione a opção "Conte" em seguida
          seleciona a opção "Excluir conta".
        </li>
      </ul>
    </div>
  );
}

export default App;
